import { useEffect, useState } from 'react';
import { APiUrl,GeoUrl,GeoAPiUrl,GeoAdminUrl } from '../constants/global';
import { $ } from 'react-jquery-plugin';


export default function ManageUsers(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    $('.password').hide();

    $('.btn-close').on('click', () => { $('#userform').hide(); });
    function submitForm(e) {
        e.preventDefault();
        const data = {
            id: $('#id').val(),
            status: $('#status').val(),
            email: $('#email').val(),
            name: $('#name').val(),
            username: $('#username').val(),
            lastname: $('#lastname').val(),
            action: $('#action').val(),
        }
        if ($('#action').val() === 'register') {
            data['password'] = $('.password').val();

        }
        fetch(GeoAPiUrl + 'EditUser', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    $('#userform').hide();
                    setIsLoaded(false);

                },
                (error) => {
                    $('#userform').hide();
                }
            )
    }
    function handleClick(e) {
        const uid = e.target;
        console.log(uid.id)
        $('#action').val(uid.id);
        if (uid.id === 'edituser' || uid.id === 'deluser') {
            const dat = {
                Action: uid.id,
                usid: uid.getAttribute('uid'),
            }

            fetch(GeoAPiUrl + 'Users', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dat)
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.hasOwnProperty('user')) {
                            const user = result.user[0];
                            $('#id').val(user.id);
                            $('#lastname').val(user.lastname);
                            $('#status').val(user.status);
                            $('#username').val(user.username);
                            $('#email').val(user.email);
                            $('#name').val(user.name);
                            $('#userform').show();
                        }
                        if (result.hasOwnProperty('deluser')) {
                            console.log($('tr[data-id="'+result.deluser+'"]'));
                            $('tr[data-id="'+result.deluser+'"]').remove();
                        }

                        console.log(result);


                    },
                    (error) => {
                        $('#userform').hide();
                    }
                )
        }  else {
            $('#id').val('');
            $('#lastname').val('');
            $('#status').val('');
            $('#username').val('');
            $('#email').val('');
            $('#name').val('');
            $('#password').val('');
            $('.password').show('');
            $('#userform').show();
        }

    }
    useEffect(() => {

        fetch(GeoAPiUrl + 'UsersList', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'list',
                Action: 'UsersList',
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.post);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [isLoaded])

    if (error) {
        return <div>შეცდომა: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>ჩატვირთვა...</div>;
    } else {
        return (
            <>
                <div style={{ 'padding': '3px' }}>
                    <button id='register' onClick={handleClick} className="btn btn-outline-success rounded-1" >მომხმარებლის დამატება</button>
                    <table className="table table-bordered table-light table-striped table-hover border border-dark">
                        <thead>
                            <tr className='border-dark table-secondary'>
                                <th className='border-dark table-secondary'>ID</th>
                                <th className='border-dark table-secondary'>სახელი,გვარი</th>
                                <th className='border-dark table-secondary'>userName</th>
                                <th className='border-dark table-secondary'>ელ. ფოსტა</th>
                                <th className='border-dark table-secondary'>სტატუსი</th>
                                <th className='border-dark table-secondary'>თარიღი</th>
                                <th className='border-dark table-secondary'>რედაქტირება</th>
                                <th className='border-dark table-secondary'>წაშლა</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr className='border border-dark' data-id={item.id} key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name} {item.lastname}</td>
                                    <td>{item.username}</td>
                                    <td>{item.email}</td>
                                    <td>{item.status}</td>
                                    <td>{item.created_date}</td>
                                    <td><button uid={item.id} type="button" className="btn btn-outline-success rounded-1" id='edituser' onClick={handleClick}>რედაქტირება</button></td>
                                    <td><button uid={item.id} type="button" className="btn btn-outline-danger rounded-1" id='deluser' onClick={handleClick}>წაშლა</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{ 'padding': '3px', 'display': 'none' }} id='userform'>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button type="button" className="btn-close float-right" aria-label="Close"></button>
                    </div>
                    <form className="was-validated w-75 mx-auto my-5" id='edituserform' onSubmit={submitForm}>
                        <div className="form-row">
                            <input type="hidden" id="id" name="id" />
                            <input type="hidden" id="action" name="action" value='user' />
                            <div className="form-floating mb-3">
                                <input type="text" defaultValue={''} className="form-control is-invalid" id="name" name="name" required />
                                <label htmlFor="name">სახელი</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" defaultValue={''} className="form-control is-invalid" id="lastname" name="lastname" required />
                                <label htmlFor="lastname">გვარი</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" defaultValue={''} className="form-control is-invalid" id="username" name="username" required />
                                <label htmlFor="username">userName</label>
                            </div>
                            <div className="form-floating mb-3 password">
                                <input type="password" defaultValue={''} className="form-control is-invalid" id="pass" name="pass" required />
                                <label htmlFor="pass">პაროლი</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" defaultValue={''} className="form-control is-invalid" id="email" name="email" required />
                                <label htmlFor="email">ელ.ფოსტა</label>
                            </div>
                            <div className="form-floating mb-3">

                                <select defaultValue={''} className="form-select form-control is-invalid" id="status" name="status" aria-label="სტატუსი" required >
                                    <option value=''>აარჩიეთ</option>
                                    <option value="0"  >პასიური</option>
                                    <option value="1" >აქტიური</option>
                                </select>
                                <label htmlFor="status">სტატუსი</label>
                            </div>
                        </div>
                        <button className="btn btn-primary" type="submit">გაგზავნა</button>
                    </form>
                </div>
            </>
        );
    }
}