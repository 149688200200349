
import { useEffect, useState } from 'react';//useCallback,
import { APiUrl, DefaultUrl, GeoUrl, GeoAPiUrl, GeoAdminUrl } from '../constants/global';
// eslint-disable-next-line
import '../css/hotels.css';

export default function Hotels() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    //const [Formadd, setFormadd] = useState([]);




    function handleedit(e) {
        console.log(e.target.getAttribute('data-id'));
        const uid = e.target.getAttribute('data-id');
        const action = e.target.getAttribute('data-action');
        console.log(action, uid);
        fetch(GeoAPiUrl + "EditHotels", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: uid,
                action: action,
                how: 'Hotels',
            })
        })
            .then(res => res.json())
            .then(

                (result) => {
                    console.log(result);
                    if (result.status) {
                        if (typeof result.hotel !== 'boolean') {
                            document.querySelector('.addhotels').style.display = 'block';
                            document.querySelector('.addhotels #name').value = result.hotel.name;
                            document.querySelector('.addhotels #address').value = result.hotel.address;
                            document.querySelector('.addhotels #link').value = result.hotel.link;
                            document.querySelector('.addhotels input[name="action"]').value = 'editlist';
                            document.querySelector('.addhotels input[name="id"]').value = result.hotel.id;
                        }
                        
                    }
                    if(result.hotel === null){
                            window.location.reload();
                        }
                    
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);

                }
            )
        return false;
        //setFormadd(true);
        // let form = document.querySelector('#hotels_edit');
        // console.log(form.setAttribute("action",GeoAPiUrl + 'hotel.php?act=edit'));
        // form.attributes[1] = GeoAPiUrl + 'hotel.php?act=edit';
        // //form.submit();
        // console.log(e.target);
    }


    useEffect(() => {
        fetch(GeoAPiUrl + "Hotels", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'list',
                action: 'Hotels',
            })
        })
            .then(res => res.json())
            .then(

                (result) => {

                    let h = result[0];
                    //console.log(result[0]);
                    setIsLoaded(true);
                    setItems(h);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                    //console.log(error);
                }
            )


    }, [isLoaded])

    if (error) {
        return <div>შეცდომა: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>ჩატვირთვა...</div>;
    } else {
        return (
            <div className="hotels-responsive">

                <div className='buttons'>
                    <button className="button" onClick={() => {
                        //setFormadd(true);
                        document.querySelector('.addhotels').style.display = 'block';
                        document.querySelector('.addhotels #name').value = '';
                        document.querySelector('.addhotels #address').value = '';
                        document.querySelector('.addhotels #link').value = '';
                        document.querySelector('.addhotels input[name="action"]').value = 'add';
                        document.querySelector('.addhotels input[name="id"]').value = '';
                    }
                    }>დაამატე სასტუმრო</button>
                </div>
                <div className='addhotels'>
                <button type="button" className="btn-close" aria-label="Close" style={{position:'relative',right:'20px',float:'right'}} onClick={()=>{
                    document.querySelector('.addhotels').style.display = 'none';
                }}></button>
                    <h3>სასტუმროს შეტანა</h3>

                    <div className="container">
                        <form id="hotels_edit" action={`${GeoAPiUrl}hotel.php`} method='post' encType='multipart/form-data'>
                            <input type='hidden' name='id' value='' />
                            <input type='hidden' name='action' value='add' />
                            <label htmlFor="name">სასტუმროს სახელი</label>
                            <input type="text" id="name" name="name" placeholder="სასტუმროს სახელი.." />

                            <label htmlFor="address">მისამართი</label>
                            <input type="text" id="address" name="address" placeholder="სასტუმროს მისამართი.." />

                            <label htmlFor="link">სასტუმროს ბმული (ლინკი)</label>
                            <input type="text" id="link" name="link" placeholder="ბმული (ლინკი).." />

                            <label htmlFor="img">სასტუმროს სურათი: </label>
                            <input type="file" id="img" name="img" placeholder="სურათი" />

                            <input type="submit" value="ჩაწერა" />
                        </form>
                    </div>

                </div>


                {items && items.map((item, i) => {
                    return (
                        <div className="card" key={i} >
                            <div className='hotel_img' style={{ backgroundImage: `url(${GeoUrl}images/hotels/${item.img})` }}>
                            </div>
                            {/* <img src={`${GeoUrl}/images/hotels/${item.img}`} data-img={item.img} alt={item.name} style={{ width: '100%' }} /> */}
                            <div className="container">
                                <a href={item.link} target='_blank' rel="noreferrer" > <h4><b data-name={item.name}>{item.name}</b></h4>
                                    <p data-address={item.address}>{item.address}</p>
                                </a>
                            </div>
                            <div className='edithotel'>
                                <span>
                                    <i className="fa-regular fa-pen-to-square" data-id={item.id} data-action='edit' onClick={handleedit}></i>
                                </span>
                                <span>
                                    <i className="fa-solid fa-trash-can" data-id={item.id} data-action='delete' onClick={handleedit}></i>
                                </span>
                            </div>
                        </div>
                    );
                })
                }
            </div>

        );

    }
};

