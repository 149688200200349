import { useEffect, useState, useRef } from "react";
//import { Navigate ,useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { isset, DefaultUrl, images_upload_url, GeoUrl, GeoAPiUrl, Geoimages_upload_url } from '../constants/global';
import { openFile, upload_File, getDataOnSend } from '../services/post';

// eslint-disable-next-line
function EditForm(props) {
    //console.log(props);

    const p = props.items || {
        active: 1,
        addate: "",
        city: "",
        gdate: "",
        gidi: 0,
        id: '',
        img: "",
        lang: "en",
        period: "",
        person: '',
        price: '',
        shortext: "",
        text: "",
        valuta: "",
    };

    const [progress, setProgress] = useState('');
    // სურათის ატვირთვა
    const handleImageUpload = (blobInfo, progress, failure) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", Geoimages_upload_url, true);

            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            xhr.upload.onprogress = (e) => {
                progress((e.loaded / e.total) * 100);
                if (progress && typeof progress === "function") {
                    const percent = 0;
                    progress(percent);
                }
            };
            xhr.onload = () => {
                if (xhr.status === 403) {
                    reject({ message: "HTTP Error: " + xhr.status, remove: true });
                    return;
                }
                if (xhr.status < 200 || xhr.status >= 300) {
                    reject("HTTP Error: " + xhr.status);
                    return;
                }

                const json = JSON.parse(xhr.responseText);

                if (!json || typeof json.location != "string") {
                    reject("Invalid JSON: " + xhr.responseText);
                    return;
                }

                resolve(json.location);
            };

            xhr.onerror = () => {
                reject({ message: "Image upload failed", remove: true });
                if (failure && typeof failure === "function") {
                    failure("Image upload failed");
                }
            };
            xhr.send(formData);
        });
    };
    // ფორმის ელემენტები
    const { register, handleSubmit } = useForm();

    let [initialValue, setInitialValue] = useState("");

    //const [city, setMessage] = useState('');

    useEffect(() => {
        setTimeout(() => setInitialValue(''), 500);
    }, []);
    const [value, setValue] = useState(initialValue ?? '');

    useEffect(() => setValue(initialValue ?? ''), [initialValue]);
    const editorRef = useRef(null);
    function uploadFile(data) {
        return new Promise((resolve, reject) => {           
            const xhr = new XMLHttpRequest();
            xhr.open("POST", Geoimages_upload_url + '?postid=' + document.querySelector('#id').value, true);
            let formData = new FormData();
            formData.append("file", data.target.files[0]);
            xhr.onload = () => {
                   if (xhr.status === 403) {
                    reject({ message: "HTTP Error: " + xhr.status, remove: true });
                    return;
                }
                if (xhr.status < 200 || xhr.status >= 300) {
                    reject("HTTP Error: " + xhr.status);
                    return;
                }
                const json = JSON.parse(xhr.responseText);
                if (json) {
                    document.querySelector('#postimg').src = json.location;
                    document.querySelector('#img').value = json.location;
                    document.querySelector('#id').value = json.id;
                    setProgress('');
                };
                if (!json || typeof json.location != "string") {
                    reject("Invalid JSON: " + xhr.responseText);
                    return;
                }


            };
            xhr.onreadystatechange = function (e) {
                if (4 === this.readyState) {
                    console.info([e.target.response]);
                }
            };
            xhr.onerror = () => {
                reject({ message: "Image upload failed", remove: true });
            };
            xhr.send(formData);
        });
    }
  
    function SendHtmlData(data) {       
         getDataOnSend();
    }
    return (
        <>
            <a className="btn btn-info" href="/Manage_Posts">დაბრუნება</a>
            <form className="was-validated w-75 mx-auto my-5" onSubmit={handleSubmit((data) => SendHtmlData(data))}>
                <div className="form-row">                   
                    <input type="hidden" id="id" data-id={p.id} name="id" value={p.id} {...register("id")} />
                    <input type="hidden" id="action" name="action" value='EditPost' {...register("action")} />
                    <div className="form-floating mb-3">
                        <select defaultValue={p.lang} {...register("lang")} className="form-select form-control is-invalid" id="lang" aria-label="ენის არჩევა" required>
                            <option value=''>აარჩიეთ</option>
                            <option value="ge"  >ქართული</option>
                            <option value="en" >English</option>
                            <option value="ar" dir="rtl">عرب</option>
                            <option value="ru" >Русский</option>
                        </select>
                        <label htmlFor="lang">ენის არჩევა</label>
                    </div>
                    <div className="mb-3">
                        {p.img && <>
                            <img id='postimg' src={p.img} style={{ width: '150px' }} alt='img' onClick={openFile} />
                            <br />
                            <label htmlFor="img1" className="form-label" >სურათი</label>
                        </>
                        }
                        {!p.img && <>
                            <img id='postimg' src={GeoUrl + 'images/hotel.jpg'} style={{ width: '150px' }} alt='img' onClick={openFile} />
                            <br />
                            <label htmlFor="img1" className="form-label" >სურათი</label>
                        </>
                        }
                        <br />
                        <progress id="file" value="0" max="100" style={{ 'display': 'none', 'width': '100%' }}>  atvirTva </progress><br />
                        {p.img && <input className="form-control" type="hidden" id="img" value={p.img}  {...register("img")} />}
                        {!p.img && <input className="form-control" type="hidden" id="img" value={`${GeoUrl}images/hotel.jpg`}  {...register("img")} />}
                        <input className="form-control" type="file" id="img1" onChange={upload_File} />
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" defaultValue={p.city} {...register("city")} className="form-control is-invalid" id="city" placeholder="ტურის სახელი" required />
                        <label htmlFor="city">ტურის სახელი</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="number" defaultValue={p.period} {...register("period")} className="form-control is-invalid" id="period" placeholder="ტურის ხანგრძლივობა" required />
                        <label htmlFor="period">ტურის ხანგრძლივობა</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="number" defaultValue={p.person} {...register("person")} className="form-control is-invalid" id="person" placeholder="ტურში მონაწილეთა რაოდენობა" required />
                        <label htmlFor="person">ტურში მონაწილეთა რაოდენობა</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="number" defaultValue={p.price} {...register("price")} className="form-control is-invalid" id="price" placeholder="ტურის ფასი" required />
                        <label htmlFor="price">ტურის ფასი</label>
                    </div>
                    <div className="form-floating mb-3">

                        <select defaultValue={p.valuta} {...register("valuta")} className="form-select form-control is-invalid" id="valuta" aria-label="გადახდის ვალუტა" required>
                            <option value=''>აარჩიეთ</option>
                            <option value="0"  >ლარი</option>
                            <option value="1" >დოლარი</option>
                            <option value="2" >ევრო</option>
                        </select>
                        <label htmlFor="valuta">გადახდის ვალუტა</label>
                    </div>
                    <div className="form-floating mb-3">

                        <select defaultValue={p.active} {...register("active")} className="form-select form-control is-invalid" id="active" aria-label="ტურის ჩართვა" required>
                            <option value=''>აარჩიეთ</option>
                            <option value="0"  >გამორთვა</option>
                            <option value="1" >ჩართვა</option>
                        </select>
                        <label htmlFor="active">ტურის ჩართვა</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select defaultValue={p.gidi} {...register("gidi")} className="form-select form-control is-invalid" id="gidi" aria-label="გიდი" required>
                            <option value=''>აარჩიეთ</option>

                            <option value="0" >გამორთვა</option>
                            <option value="1" >ჩართვა</option>

                        </select>
                        <label htmlFor="gidi">გიდი</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" defaultValue={p.shortext} {...register("shortext")} className="form-control is-invalid" id="shortext" placeholder="მოკლე ტექსტი" required />
                        <label htmlFor="shortext">მოკლე ტექსტი</label>
                    </div>
                    <div className="form-floating mb-3">
                        <Editor
                            apiKey="rd9sspzj7gc1sormw02usl0gj7fp9i0vxmy0p3y8dedszlp7"
                            id='content'
                            initialValue={p.text}
                            //onEditorChange={(newValue, editor) => setValue(newValue)}
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                                height: 500,
                                plugins: [
                                    "advlist",
                                    "autolink",
                                    "lists",
                                    "link",
                                    "image",
                                    "charmap",
                                    "preview",
                                    "anchor",
                                    "searchreplace",
                                    "visualblocks",
                                    "code",
                                    "fullscreen",
                                    "insertdatetime",
                                    "media",
                                    "table",
                                    "code",
                                    "help",
                                    "wordcount",
                                ],
                                toolbar:
                                    "undo redo | media| styles | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                                images_upload_url: images_upload_url,
                                automatic_uploads: true,
                                images_reuse_filename: true,
                                images_upload_handler: handleImageUpload,
                                relative_urls: false,
                                remove_script_host: true,
                                document_base_url: DefaultUrl
                            }}
                        />

                    </div>
                </div>
                <button className="btn btn-primary" type="submit">გაგზავნა</button>
            </form>
        </>
    );
}
export default function EditPost() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const params = window.location.pathname.split('/');
    //console.log(params);

    const p0 = isset(params[2]) || false;



    useEffect(() => {
        fetch(GeoAPiUrl + 'EditPost', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(['', 'EditPost', params[2]])
        })
            .then(res => res.json())
            .then(
                (result) => {

                    setIsLoaded(true);
                    setItems(result['post']);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
        // eslint-disable-next-line
    }, [])

    if (error) {
        return <div>შეცდომა: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>ჩატვირთვა...</div>;
    } else {
        return (
            <>
                {p0 && <EditForm items={items} />}
            </>
        );
    }


}
