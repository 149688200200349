const protocol=window.location.protocol;
const host=window.location.hostname;
const port= window.location.port;

// eslint-disable-next-line
export const DefaultUrl = protocol+"//"+host+(port===80 || port===443?'':":"+port) ;
// eslint-disable-next-line
export const Url = protocol+"//"+host ;
// eslint-disable-next-line
export const APiUrl=protocol+"//"+host+"/api/";
// eslint-disable-next-line
export const GeoAPiUrl="https://admin.geotravels.ge/api/";
// eslint-disable-next-line
export const GeoUrl="https://geotravels.ge/";
// eslint-disable-next-line
export const geotravels="https://geotravels.ge/";
// eslint-disable-next-line
export const Geoimages_upload_url= GeoAPiUrl+"server.php";
// eslint-disable-next-line
export const images_upload_url= APiUrl+"server.php";
// eslint-disable-next-line
export const AdminUrl=DefaultUrl+"/";
// eslint-disable-next-line
export const GeoAdminUrl="https://admin.geotravels.ge/";
// eslint-disable-next-line
export const itemUrl=APiUrl+"items";
// eslint-disable-next-line
export const testUrl=APiUrl+"test.php";
//http://localhost/api/items
// eslint-disable-next-line
export  function isset(ref) { return typeof ref !== 'undefined' }

//export { DefaultUrl, images_upload_url, AdminUrl,APiUrl,geotravels,isset ,testUrl,itemUrl ,Url,GeoUrl,GeoAPiUrl,GeoAdminUrl,Geoimages_upload_url};
/*const protocol=window.location.protocol;
const host=window.location.hostname;
const port= window.location.port;


const DefaultUrl = protocol+"//admin.geotravels.com" ;
const APiUrl=protocol+"//geotravels.com/api/";
const images_upload_url= APiUrl+"server.php";

const AdminUrl=DefaultUrl+"/";

const itemUrl=APiUrl+"items";
const testUrl=APiUrl+"test.php";
//http://localhost/api/items
function isset(ref) { return typeof ref !== 'undefined' }

export { DefaultUrl, images_upload_url, AdminUrl,APiUrl,isset ,testUrl,itemUrl };*/