import React, { useState } from "react";
//import { Link, useNavigate } from 'react-router-dom'
import { APiUrl,GeoUrl,GeoAPiUrl,GeoAdminUrl } from '../constants/global';
const CustomNav = () => {
  const [window, setWindow] = useState(false);

  let openClose = () => {
    let d=document.body.offsetWidth;
    if (window === false) {
      setWindow(true);      
      document.querySelector('main').style.width=(d-60)+'px';
      document.querySelector('aside').style.width='60px';
    } else {
      setWindow(false);
      document.querySelector('main').style.width=(d-250)+'px';
      document.querySelector('aside').style.width='250px';
    }
  };
  let menu = [
    ["Reservation", GeoAdminUrl+"img/dashboard.svg", "/"],
    ["Manage User’s", GeoAdminUrl+"img/manage user.svg", "/Manage_Users"],
    ["Manage Posts", GeoAdminUrl+"img/manage  order.svg", "/Manage_Posts"],
    ["Imagegalery", GeoAdminUrl+"img/gallery.svg", "/Imagegalery"],
    ["Holels",  GeoAdminUrl+"img/hotel.svg", "/hotels"]
  ];
 
  return (
    <nav className="navbar-menu" style={{ width: window === false ? 250 : 60 }}>
      <div className="burger" onClick={() => openClose()}>
        <img src={`${GeoAdminUrl}img/menu.svg`} alt="burger" />
      </div>
      <ul className="navbar__list">
        {menu.map((item, i) => (  
            <li className="navbar__li-box" key={i}>              
              <a href={item[2]}>
              <img
                src={item[1]}
                alt={item[1]}
                style={{ paddingLeft: window === false ? 27 : 17 }}
              />
              <div
                className="navbar__li"
                style={{ display: window === false ? "inline-block" : "none" }}
              >
                {item[0]}
              </div>
              </a>
            </li>          
        ))}
      </ul>
    </nav>
  );
};

export default CustomNav;
