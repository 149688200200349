import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { APiUrl } from '../constants/global';
// import jwtDecode from 'jwt-decode';
// const { jwt_decode } = require("jwt-decode");

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export default function Profile() {
  const [user, setUser] = useState()
  const userToken = parseJwt(localStorage.getItem('token'))

  const getUser = useCallback(async () => {
    try {
      await fetch(APiUrl+'api.php', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setUser(data.status)
        })
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    getUser()
  }, [getUser])

  return (
    <div className="profile">
      <h2>პროფილი</h2>
      {user && (
        <>
          <label>სახელი: {user.name}</label>
          <label>გვარი: {user.lastname}</label>
          <label>Username: {user.username}</label>
          <label>ელ. ფოსტა: {user.email}</label>
        </>
      )}
      <h2>მომხმარებელი ტოკენიდან</h2>
      {userToken && (
        <>
          <label>სახელი: {userToken.user.name}</label>
          <label>გვარი: {userToken.user.lastname}</label>
          <label>Username: {userToken.user.username}</label>
          <label>ელ. ფოსტა: {userToken.user.email}</label>
        </>
      )}
      <Link to="/">მთავარი</Link>
    </div>
  )
}