import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';//useCallback,
import {  GeoUrl, GeoAPiUrl } from '../constants/global';
// eslint-disable-next-line


export default function ManagePosts() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    function handleClick(e) {
        const uid = e.target.getAttribute('uid');

        fetch(GeoAPiUrl + "DelPosts", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'Method': 'list',
                'Action': 'DelPosts',
                'id': uid
            })
        })
            .then(res => res.json())
            .then(

                (result) => {
                    document.querySelector('#p'+uid).remove();
                    console.log(result)
                },
                (error) => {

                    console.log(error);
                }
            )
    }
    function hideLang(lang) {
        for (let x of document.querySelectorAll('tbody tr')) {
            x.style.display = (lang !== '') ? 'none' : 'table-row';
        }

        if (lang === '') { return false; }
        let tr = document.querySelectorAll('tbody tr[' + lang + ']')
        for (let x of tr) {
            x.style.display = 'table-row'
        }
    }
    useEffect(() => {

        fetch(GeoAPiUrl + "Posts", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'list',
                Action: 'Posts',
            })
        })
            .then(res => res.json())
            .then(

                (result) => {

                    let h = result.posts;
                    //console.log(h);
                    setIsLoaded(true);
                    setItems(h);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                    console.log(error);
                }
            )


    }, [isLoaded])

    if (error) {
        return <div>შეცდომა: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>ჩატვირთვა...</div>;
    } else {
        let header = ['#', 'რედაქტირება', 'ენა', 'ტური', 'დამატების თარიღი', 'სურათი', 'სტატუსი', 'წაშლა'];
        return (
            <div className="table-responsive">
                <a href='/EditPost/new' className='btn btn-info' style={{ margin: '5px', borderRadius: '5px' }}>ტურის დამატება</a>
                <br />
                <div className='container justify-content-center align-items-center d-flex border border-primary' style={{'height':'50px'}}>
                    <label className=' ' style={{'width':'auto'}}>&nbsp;<input type='radio' className='form-check-input d-inline-block' value='0' name='cnglang' onClick={() => hideLang('')} /> all</label> &nbsp;&nbsp;
                    <label className=' ' style={{'width':'auto'}}><input type='radio' className='form-check-input d-inline-block' value='1' name='cnglang' onClick={() => hideLang('lang="en"')} /> English</label> &nbsp;&nbsp;
                    <label className=' ' style={{'width':'auto'}}><input type='radio' className='form-check-input d-inline-block' value='2' name='cnglang' onClick={() => hideLang('lang="ge"')} /> ქართული</label> &nbsp;&nbsp;
                    <label className=' ' style={{'width':'auto'}}><input type='radio' className='form-check-input d-inline-block' value='3' name='cnglang' onClick={() => hideLang('lang="ru"')} /> რუსული</label> &nbsp;&nbsp;
                    <label className=' ' style={{'width':'auto'}}><input type='radio' className='form-check-input d-inline-block' value='3' name='cnglang' onClick={() => hideLang('lang="ar"')} /> არაბული</label>
                </div>
                <table className="table table-bordered table-light table-striped table-hover">
                    <thead className="thead-dark">
                        <tr>
                            {header.map((item, i) => (
                                <th key={i} scope="col">{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items && items.map((item, i) => {
                            return (
                            <tr key={item.id} lang={item.lang} id={`p${item.id}`}>
                                <th scope="row">{item.id}</th>
                                <td>
                                    <Link to={`/EditPost/${item.id}`} >edit</Link>

                                </td>
                                <td>
                                    {item.lang === 'en' && (<img src={`${GeoUrl}img/en.png`} style={{ width: '15px' }} alt='en' />)}
                                    {item.lang === 'ge' && (<img src={`${GeoUrl}img/ge.png`} style={{ width: '15px' }} alt='ge' />)}
                                    {item.lang === 'ar' && (<img src={`${GeoUrl}img/ar.png`} style={{ width: '15px' }} alt='ar' />)}
                                    {item.lang === 'ru' && (<img src={`${GeoUrl}img/ru.png`} style={{ width: '15px' }} alt='ru' />)}
                                </td>
                                <td>{item.city}</td>
                                <td>{item.addate}</td>
                                <td><img src={item.img} style={{ width: '20px' }} alt={item.city} /></td>
                                <td>{item.active !== 0 ? <span style={{ color: "blue", fontSize: 22, fontWeight: '700' }}>√</span> : <span style={{ color: "red", fontWeight: '700' }}>X</span>}</td>
                                <td><button uid={item.id} type="button" className="btn btn-outline-danger rounded-1" id='deluser' onClick={handleClick}>წაშლა</button></td>
                            </tr>);
                        })

                        }
                    </tbody>
                </table>
            </div>

        );
    }
};
