import { useState } from 'react';
import { Link } from 'react-router-dom';
import { APiUrl } from '../constants/global';
export default function Login() {
  //const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const usernameHandler = (event) => {
    setUsername(event.target.value)
  }

  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }

  const submitHandler = (event) => {
    event.preventDefault()
    loginRequest()
  }

  async function loginRequest() {
    try {
      await fetch(APiUrl+'login', {
        method: 'POST',
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      })
        .then((respose) => {
          
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          console.log(data);
          if (data[0].status) {
            localStorage.setItem('token', data.status)
            //navigate('/Dashboard');
            document.location.href='/Dashboard';
          } else {
            document.querySelector('.alert').textContent='მომხმარებლის პაროლი ან სახელი არ ემთხვევა'
          }
        })
    } catch (error) {
      console.log(error.message, error)
    }
  }

  return (
    <form className="login-form" onSubmit={submitHandler}>
      <h2>შესვლა</h2>
      <div className="alert alert-warning" role="alert"></div>
      <label htmlFor='username'>Username</label>
      <input id='username' type="text" value={username} onChange={usernameHandler} />
      <label htmlFor='pass'>პაროლი</label>
      <input id="pass" type="password" value={password} onChange={passwordHandler} />
      <button>შესვლა</button>
      <div style={{width:'100%'}}>
        
        <Link to="/reset">პაროლის აღდგენა</Link>
      </div>

    </form>
  )
}