import { useEffect, useState } from 'react';//useCallback,
import { APiUrl, DefaultUrl, Url, GeoUrl,GeoAPiUrl,GeoAdminUrl } from '../constants/global';
import axios from "axios";


import PhotoAlbum from "react-photo-album";

import "../css/galery.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";



export default function Imagegalery() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [files, setFiles] = useState(null);
    const [fileSize, setFileSize] = useState(0);
    const [msg, setMsg] = useState(null);
    const [progress, setProgress] = useState({ started: false, pc: 0 });

    function checkbites(file) {
        let i = 0;
        for (let r of file) {
            i += r.size;            
        }
        setFileSize(i);
        
    }
    const [index, setIndex] = useState(-1);
    function delimage(e) {
        const img=e.target.attributes[1].value;
        fetch(GeoAPiUrl + "uploads.php", {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: img,
                Action: 'Delete',
            })
        })
            .then(res => res.json())
            .then(
                
                (result) => {                    
                    let h = result; 
                    console.log(h);
                    window.location.reload();
                   // document.querySelector('img[title="'+h.name+'"').parentElement.remove();                  
                },
                (error) => {                    
                    console.log(error);
                }
            )
        console.log(e.target.attributes[1].value)//e.target.attributes[1].value
    }
    function updateProgress(evt) {
        if (evt.lengthComputable) {
            var percentComplete = (evt.loaded / evt.total) * 100;
            // $('#progressbar').progressbar("option", "value", percentComplete);
            setProgress(prevState => {
                return { ...prevState, pc: percentComplete }
            })
        }
    }
    function hundleUpload() {
        console.log(fileSize);
        if(fileSize>41943040){
            setMsg('სურათ(ებ)ის ზომა აღემატება დასაშვებ ზღვარს. მაქს. 5 გბ');
            document.querySelector('input').value='';
            return false;
        }
        return new Promise((resolve, reject) => {
            setProgress(prevState => {
                return { ...prevState, started: true }
            })
            const xhr = new XMLHttpRequest();
            xhr.open("POST", GeoAPiUrl + "uploads.php", true);
            xhr.onprogress = updateProgress;
            const fd = new FormData();
            if (files === null) {
                setMsg('სურათი არ არის არჩეული');
                return false;
            } else {
                setMsg(null);
            }
            
            for (let i = 0; i < files.length; i++) {
                fd.append(`file${i + 1}`, files[i]);
            }
            xhr.onload = () => {
                console.log(xhr.status)

                if (xhr.status === 200) {
                    window.location.reload();
                    return;
                }
                if (xhr.status === 403) {
                    reject({ message: "HTTP Error: " + xhr.status, remove: true });
                    return;
                }
                if (xhr.status < 200 || xhr.status >= 300) {
                    reject("HTTP Error: " + xhr.status);
                    return;
                }
                const json = JSON.parse(xhr.responseText);
                if (json) {
                    console.log(json.images);
                    for (let i of json.images) {

                    }

                };



            };
            xhr.onreadystatechange = function (e) {
                if (4 === this.readyState) {
                    const img = e.target.response;
                    console.info(img.length, this);
                    
                }
            };
            xhr.onerror = () => {
                reject({ message: "Image upload failed", remove: true });
            };
            xhr.send(fd);

        });

    }
    useEffect(() => {

        fetch(GeoAPiUrl + "images.php", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'listDir',
                Action: 'galery',
            })
        })
            .then(res => res.json())
            .then(

                (result) => {
                    let h = result.files;

                    setIsLoaded(true);
                    setItems(h);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                    console.log(error);
                }
            )


    }, [isLoaded])


    if (error) {
        return <div>შეცდომა: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>ჩატვირთვა...</div>;
    } else {

        return (
            <>
                <div className='uploadFiles'>
                    <h2 className='uploadH2'>სურათების ატვირთვა</h2>

                    <input className='upload' multiple onChange={(e) => { setFiles(e.target.files); checkbites(e.target.files); }} type="file" />

                    <button className='uloadBtn' onClick={hundleUpload}>ატვირთვა</button><br />

                    {progress.started && <progress className='uploadprogress' max='100' value={progress.pc}></progress>}

                    {msg && <span className='uploadimg'>{msg}</span>}
                </div>
                <div className='showImagesgalery'>

                    <PhotoAlbum
                        layout="rows"
                        photos={items}
                        sizes={{
                            size: "992px",
                            sizes: [
                              { viewport: "(max-width: 767px)", size: "calc(100vw - 32px)" },
                              { viewport: "(max-width: 1279px)", size: "calc(100vw - 288px)" },
                            ],
                          }}
                        onClick={({ index }) => setIndex(index)}
                        renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                            <div style={{ position: "relative", ...wrapperStyle }}>
                                {renderDefaultPhoto({ wrapped: true })}
                                {photo.title && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            overflow: "hidden",
                                            backgroundColor: "rgba(255 255 255 / .6)",
                                            color: 'red',
                                            cursor: 'pointer',
                                            inset: "auto 0 0 0",
                                            padding: 8,
                                        }}
                                    >
                                        <i className="fa-solid fa-trash-can" onClick={delimage} data-name={photo.title}></i>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                         {photo.title}
                                    </div>
                                )}
                            </div>
                        )}
                    />
                    <Lightbox
                        slides={items}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        // enable optional lightbox plugins
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                    />

                </div>
            </>
        );
    }
};
