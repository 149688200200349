//import { useEffect, useState, useRef } from "react";isset, DefaultUrl, images_upload_url, GeoUrl, GeoAPiUrl,
// eslint-disable-next-line
import { Geoimages_upload_url } from '../constants/global';

function disable_elem(elem) {
    // console.log('disable_elem');
    let contval = ['submit', 'button', 'hidden', 'img1', 'textarea'];
    for (const key of elem) {
        if (key.type === 'submit' || key.type === 'button' || key.type === 'hidden' || key.id === 'img1' || key.type === 'textarea') continue;
        if (!key.hasAttribute('disabled')) {
            key.toggleAttribute("disabled");
        }
    }
}
function writeData(data){
    console.log(data,JSON.stringify(data));//GeoAPiUrl + 
     fetch('https://admin.geotravels.ge/api/UpPost.php', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data.status === 1) {
                    console.log(data)
                    //<Navigate to="/Manage_Posts" replace={true} />
                    window.location.href = window.location.origin + '/Manage_Posts';

                }
            })
            .catch((error) => {
                console.info("Error:" + error);
            });
}

function enable_elem(elem) {
    // console.log('enable_elem');
    for (const key of elem) {
        if (key.type === 'submit' || key.type === 'button' || key.type === 'hidden' || key.id === 'img1' || key.type === 'textarea') continue;
        if (key.hasAttribute('disabled')) {
            key.toggleAttribute("disabled");
        }
    }
}

function addvalue(data) {    
    if (document.querySelector('#id').value == '') {
        let lang0 = { '': 0, 'ge': 1, 'en': 2, 'ar': 3, 'ru': 4 };
        let valuta = { 'GEL': 0,'USD': 1,  'EURO': 2, 'ar': 3, 'ru': 4 };
        document.querySelector('#postimg').src = data.location;
        document.querySelector('#img').value = data.location;
        document.querySelector('#id').value = data.id;
        document.querySelector('#id').setAttribute('data-id', data.id)
        document.querySelector('#city').value = data.data.city;
        document.querySelector('#period').value = data.data.period;
        document.querySelector('#person').value = data.data.person;
        document.querySelector('#price').value = data.data.price;
        document.querySelector('#shortext').value = data.data.shortext;
        document.querySelector('#lang').selectedIndex = lang0[data.data['lang']];
        document.querySelector('#valuta').selectedIndex =data.data.valuta;
        document.querySelector('#active').selectedIndex =data.data.active;
        document.querySelector('#gidi').selectedIndex =data.data.gidi;
        document.querySelector('#content').value =data.data.text;;
    }

}
export function getDataOnSend() {
    let temp;
    let form_ = window.document.forms[0];
    let fD = new Object();
    for (const key of form_) {
        if (key.type === 'submit' || key.type === 'button' || key.id === 'img1') continue;
        temp = key.type;
        fD[key.id]=key.value;
    } 
    console.log(fD)   
    writeData(fD);
    
}
export function handle_Image_Upload(blobInfo, progress, failure) {
    console.log(blobInfo, progress, failure);

}


function httpclient(reject, data) {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", 'https://admin.geotravels.ge/api/t.php', true);
    let formData = new FormData();
    formData.append("file", data.target.files[0]);
    //formData.append("file", blobInfo.blob(), blobInfo.filename());
    //console.log(document.querySelector('#id').value);
    formData.append("id", document.querySelector('#id').value);
    xhr.upload.onprogress = (e) => {
        document.querySelector('progress').style.display = 'block';
        const prog = ((e.loaded / e.total) * 100);
        document.querySelector('progress').value = prog;
        if (prog > 99) {
            setTimeout(() => {
                document.querySelector('progress').style.display = 'none';
            }, 5000)
        }
    };
    xhr.onload = () => {

        if (xhr.status === 403) {
            reject({ message: "HTTP Error: " + xhr.status, remove: true });
            return;
        }
        if (xhr.status < 200 || xhr.status >= 300) {
            reject("HTTP Error: " + xhr.status);
            return;
        }
        const json = JSON.parse(xhr.responseText);
        if (json) {
            addvalue(json);
        };
        if (!json || typeof json.location != "string") {
            reject("Invalid JSON: " + xhr.responseText);
            return;
        }
    };
    xhr.onreadystatechange = function (e) {
        if (4 === this.readyState) {
            //console.info([e.target.response]);
        }
    };
    xhr.onerror = () => {
        reject({ message: "Image upload failed", remove: true });
    };
    xhr.send(formData);
}

// eslint-disable-next-line
export function upload_File(data) {
    let formElem = data.target.form;
    let file = data.target.files[0];
    if (file === undefined) {
        enable_elem(formElem);
        return 0;
    }
    disable_elem(formElem)
    return new Promise((resolve, reject) => {
        httpclient(reject, data);
        enable_elem(formElem);
    });
    //console.log(data,file)
}
export function openFile() {
    let imGfile = document.querySelector('#img1');
    imGfile.click();
}
