import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { APiUrl,GeoUrl,GeoAPiUrl,GeoAdminUrl } from './constants/global';

import Logout from './view/Logout';
import AccountConfirm from './view/confirm';
import Home from './view/home';
import Login from './view/Login';
import Profile from './view/profile';
import Register from './view/register';
import ResetPassword from './view/reset';
import ManagePosts from "./view/ManagePosts";
import MananeUsers from './view/ManageUsers';
import Imagegalery from './view/Imagegalery';
import Hotels from "./view/Hotels";
import CustomNav from "./components/CustomNav";
import EditPost from "./view/EditPost";

import './App.css';

function App() {
  //const params = window.location.pathname.split('/');

  return (
    <div className="home">
      {localStorage.getItem('token') && 
      <header className='AdminHeader container-fluid'>
        <div className="container-fluid position-relative p-0">
                  <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                      <a href={GeoAdminUrl} className="navbar-brand p-0">
                          <img src={`${GeoAdminUrl}/img/logo-1.png`} alt="Logo" />
                      </a>                      
                      {localStorage.getItem('token') && <a href="/logout">გასვლა</a> }
                  </nav>
              </div>
        </header>
        }
        <div className='container-fluid d-flex'>
      <aside>
        {localStorage.getItem('token') && <CustomNav />}
      </aside>
      <main>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/confirm" element={<AccountConfirm />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/Manage_Users" element={<MananeUsers />} />
            <Route path="/Manage_Posts" element={<ManagePosts />} />
            <Route path="/Imagegalery" element={<Imagegalery />} />
            <Route path="/hotels" element={<Hotels />} />
            <Route path="/Manage_Coupon" element={<Home />} />
            <Route path="/SendSuccessfully" element={<Home  />} />
            <Route path="/Logout" element={<Logout />} />
            <Route path="/EditPost/*" element={<EditPost />} />
            <Route path="*" element={<p>გვერდი არ მოიძებნა</p>} />

          </Routes>
        </BrowserRouter>
      </main>
      </div>
    </div>
  )
}
export default App;
//
/*

      */