//import { Link ,Outlet} from 'react-router-dom';

import { useEffect, useState } from 'react';
import { APiUrl, GeoUrl, GeoAPiUrl, GeoAdminUrl } from '../constants/global';
import Login from './Login.js';

export default function Home() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  function dellick(e) {
    const uid = e.target.getAttribute('uid');
    const action = e.target.getAttribute('action');
    
    fetch(GeoAPiUrl + 'bookingsactions', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id:uid,
        action:action,
        page:'bookingsactions',
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.status){
            window.location.href = window.location.origin + '/';
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }
  useEffect(() => {

    fetch(GeoAPiUrl + "bookings")
      .then(res => res.json())
      .then(
        (result) => {
          let h = result.bookings;
          setIsLoaded(true);
          setItems(h);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])
  if (error) {
    return <div>error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    let header = ['#', 'ტური', 'სახელი', 'მესიჯი', 'კონტაქტი', 'დამატების თარიღი', 'ტურის იდ', 'წაშლა', 'არქივირება'];
    return (
      <>
        {!localStorage.getItem('token') && <div className="panel"><Login /></div>}
        {localStorage.getItem('token') && (
          <div className="table-responsive">

            {items && <table className="table table-bordered table-light table-striped table-hover mw-100">
              <thead className="thead-dark">
                <tr>
                  {header.map((item, i) => (
                    <th key={i} scope="col" className='border border-dark'>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {items.map((item, i) => {
                  return (<tr key={item.id} data-id={item.id}>
                    <th scope="row" className='border border-dark w-1'>{item.id}</th>
                    <td className='border border-dark'>
                      {item.tour}
                    </td>
                    <td className='border border-dark'>
                      {item.name}
                    </td>
                    <td className='border border-dark'>{item.message}</td>
                    <td className='border border-dark'>email: {item.email} <br />tel: {item.phone}</td>
                    <td className='border border-dark'>{item.addDate}</td>
                    <td className='border border-dark'>{item.tourid}</td>
                    <td className='border border-dark'>
                      <button uid={item.id} action="delete" className='btn btn-outline-danger rounded-1' onClick={dellick}>წაშლა</button>
                    </td>
                    <td className='border border-dark'>
                      <button uid={item.id} action="archive" className='btn btn-outline-danger rounded-1' onClick={dellick}>არქივი</button>
                    </td>
                  </tr>);
                })

                }
              </tbody>
            </table>
            }
            {!items && <output>ჯავშანი არ არის</output>}
          </div>
        )
        }
      </>
    )
  }
}
